import "react-app-polyfill/stable";
import "core-js";
import "babel-polyfill";
import "isomorphic-fetch";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

// import * as Sentry from "@sentry/react";

import * as serviceWorker from "./serviceWorker";

// Sentry.init({
//   dsn:
//     "https://93eabbe1dbb94d93af13b29122cdfb84@o464996.ingest.sentry.io/5476432",
//   integrations: [new Integrations.BrowserTracing()],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
