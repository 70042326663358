import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";
import React, { Suspense, lazy, memo, useState, useEffect } from "react";
// import ReactGA from 'react-ga';
import AuthApi from "./helper/AuthApi";
import Loader from "./Loader";
import CacheBuster from "./CacheBuster";

const EnterPage = lazy(() => import("./components/meetingportal/choose"));
const JudgeLogin = lazy(() => import("./components/meetingportal/login"));
const SamlLogin = lazy(() => import("./components/meetingportal/samllogin"));
const SamlLogout = lazy(() => import("./components/meetingportal/samllogout"));
const NEWDashboard = lazy(() => import("./components/newdashboard"));

const EventLanding = lazy(() => import("./components/event_landing"));
const AGM_Home = lazy(() => import("./components/AGMS/bkt/home"));
const AGM_Home_Dummy = lazy(() => import("./components/AGMS/bkt/dummyhome"));
const AGM_LOADING = lazy(() => import("./components/AGMS/bkt/event_loading"));
const AGM_Login = lazy(() => import("./components/AGMS/bkt/login"));
const AGM_Other = lazy(() => import("./components/AGMS/bkt/othersLogin"));
const AGM_Dashboard = lazy(() => import("./components/AGMS/bkt/dash"));
const MeetingNotStarted = lazy(() =>
  import("./components/meetingportal/meetingnotstarted")
);

const AGM_Authorize = lazy(() => import("./components/AGMS/bkt/authorize"));
const Preview = lazy(() => import("./components/preview"));

// GTPL Hathway MEETING
const GtplhathwayJoinMeeting = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/home")
);
const GtplhathwayLogin = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/login")
);
const Gtplhathwaydash = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/dash")
);

const GtplhathwayKFintechAuthorization = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/authorize")
);

const GtplhathwayOthersLogin = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/othersLogin")
);
const GtplhathwayMeetingNotStarted = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/meetingnotstarted")
);
// gtpl agm 2021

const GTPL_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/home")
);
const GTPL_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/login")
);
const GTPL_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/dash")
);

const GTPL_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/authorize")
);

const GTPL_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/othersLogin")
);
const GTPL_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/gtplhathwaymeeting/meetingnotstarted")
);

// justdial agm 2021
const JUSTDIAL_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/justdialagm/home")
);
const JUSTDIAL_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/justdialagm/login")
);
const JUSTDIAL_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/justdialagm/dash")
);

const JUSTDIAL_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/justdialagm/authorize")
);

const JUSTDIAL_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/justdialagm/othersLogin")
);
const JUSTDIAL_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/justdialagm/meetingnotstarted")
);

//ril agm 2023
const NCLT_Secured_Login = lazy(() =>
  import("./components/AGMS/rilagm2021/login")
);

const NCLT_Secured_Login_others = lazy(() =>
  import("./components/AGMS/rilagm2021/othersLogin")
);

const NCLT_Secured_dashboard = lazy(() =>
  import("./components/AGMS/rilagm2021/dashboard")
);

const NCLT_Secured_meeting_not_started = lazy(() =>
  import("./components/AGMS/rilagm2021/meetingnotstarted")
);

const NCLT_Secured_authorize = lazy(() =>
  import("./components/AGMS/rilagm2021/authorize")
);

const RIL_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/rilagm2021/home")
);
const RIL_AGM_2021_LANDING = lazy(() =>
  import("./components/AGMS/rilagm2021/landing")
);

const RIL_AGM_2021_terms = lazy(() =>
  import("./components/AGMS/rilagm2021/home/tnc")
);
const RIL_AGM_2021_policy = lazy(() =>
  import("./components/AGMS/rilagm2021/home/privacyPolicy")
);
const RIL_AGM_2021_thankyou = lazy(() =>
  import("./components/AGMS/rilagm2021/thankyou")
);

// // NCLT Secured

// const NCLT_Secured_Home = lazy(() =>
//   import("./components/AGMS/nclt_secured_creditors/home")
// );

// const NCLT_Secured_Login = lazy(() =>
//   import("./components/AGMS/nclt_secured_creditors/login")
// );

// const NCLT_Secured_dashboard = lazy(() =>
//   import("./components/AGMS/nclt_secured_creditors/dashboard")
// );

// const NCLT_Secured_meeting_not_started = lazy(() =>
//   import("./components/AGMS/nclt_secured_creditors/meetingnotstarted")
// );

// const NCLT_Secured_authorize = lazy(() =>
//   import("./components/AGMS/nclt_secured_creditors/authorize")
// );

// NCLT Un Secured

const NCLT_UnSecured_Home = lazy(() =>
  import("./components/AGMS/nclt_unsecure_creditors/home")
);

const NCLT_UnSecured_Login = lazy(() =>
  import("./components/AGMS/nclt_unsecure_creditors/login")
);

const NCLT_UnSecured_dashboard = lazy(() =>
  import("./components/AGMS/nclt_unsecure_creditors/dashboard")
);

const NCLT_UnSecured_meeting_not_started = lazy(() =>
  import("./components/AGMS/nclt_unsecure_creditors/meetingnotstarted")
);

const NCLT_UnSecured_authorize = lazy(() =>
  import("./components/AGMS/nclt_unsecure_creditors/authorize")
);

// Equity Shareholder

const NCLT_EQ_Home = lazy(() =>
  import("./components/AGMS/rilEQncltmeeting/home")
);
const NCLT_EQ_Login = lazy(() =>
  import("./components/AGMS/rilEQncltmeeting/login")
);
const NCLT_EQ_Dash = lazy(() =>
  import("./components/AGMS/rilEQncltmeeting/dash")
);
const NCLT_EQ_meeting_not_started = lazy(() =>
  import("./components/AGMS/rilEQncltmeeting/meetingnotstarted")
);

const NCLT_EQ_authorize = lazy(() =>
  import("./components/AGMS/rilEQncltmeeting/authorize")
);

// ---------------------TV18 NCLT START--------------------------------------
// NCLT Secured
const TV18_NCLT_Secured_Home = lazy(() =>
  import("./components/NCLT/TV18/tv18_nclt_secured_creditors/home")
);
// const TV18_NCLT_Secured_Login = lazy(() =>
//   import("./components/NCLT/TV18/tv18_nclt_secured_creditors/login")
// );
// const TV18_NCLT_Secured_dashboard = lazy(() =>
//   import("./components/NCLT/TV18/tv18_nclt_secured_creditors/dashboard")
// );
// const TV18_NCLT_Secured_meeting_not_started = lazy(() =>
//   import("./components/NCLT/TV18/tv18_nclt_secured_creditors/meetingnotstarted")
// );
// const TV18_NCLT_Secured_authorize = lazy(() =>
//   import("./components/NCLT/TV18/tv18_nclt_secured_creditors/authorize")
// );

// NCLT UnSecured
const TV18_NCLT_UnSecured_Home = lazy(() =>
  import("./components/NCLT/TV18/tv18_nclt_unsecure_creditors/home")
);
const TV18_NCLT_UnSecured_Login = lazy(() =>
  import("./components/NCLT/TV18/tv18_nclt_unsecure_creditors/login")
);
const TV18_NCLT_UnSecured_dashboard = lazy(() =>
  import("./components/NCLT/TV18/tv18_nclt_unsecure_creditors/dashboard")
);
const TV18_NCLT_UnSecured_meeting_not_started = lazy(() =>
  import(
    "./components/NCLT/TV18/tv18_nclt_unsecure_creditors/meetingnotstarted"
  )
);
const TV18_NCLT_UnSecured_authorize = lazy(() =>
  import("./components/NCLT/TV18/tv18_nclt_unsecure_creditors/authorize")
);

// Equity Shareholder
const TV18_NCLT_EQ_Home = lazy(() =>
  import("./components/NCLT/TV18/tv18EQncltmeeting/home")
);
const TV18_NCLT_EQ_Login = lazy(() =>
  import("./components/NCLT/TV18/tv18EQncltmeeting/login")
);
const TV18_NCLT_EQ_Dash = lazy(() =>
  import("./components/NCLT/TV18/tv18EQncltmeeting/dash")
);
const TV18_NCLT_EQ_meeting_not_started = lazy(() =>
  import("./components/NCLT/TV18/tv18EQncltmeeting/meetingnotstarted")
);
const TV18_NCLT_EQ_authorize = lazy(() =>
  import("./components/NCLT/TV18/tv18EQncltmeeting/authorize")
);
//----------------------TV18 NCLT END----------------------------------------

// ---------------------NETWORK18 NCLT START--------------------------------------
// NCLT Secured
const NW18_NCLT_Secured_Home = lazy(() =>
  import("./components/NCLT/NW18/nw18_nclt_secured_creditors/home")
);
// const NW18_NCLT_Secured_Login = lazy(() =>
//   import("./components/NCLT/NW18/nw18_nclt_secured_creditors/login")
// );
// const NW18_NCLT_Secured_dashboard = lazy(() =>
//   import("./components/NCLT/NW18/nw18_nclt_secured_creditors/dashboard")
// );
// const NW18_NCLT_Secured_meeting_not_started = lazy(() =>
//   import("./components/NCLT/NW18/nw18_nclt_secured_creditors/meetingnotstarted")
// );
// const NW18_NCLT_Secured_authorize = lazy(() =>
//   import("./components/NCLT/NW18/nw18_nclt_secured_creditors/authorize")
// );

// NCLT UnSecured
const NW18_NCLT_UnSecured_Home = lazy(() =>
  import("./components/NCLT/NW18/nw18_nclt_unsecure_creditors/home")
);
const NW18_NCLT_UnSecured_Login = lazy(() =>
  import("./components/NCLT/NW18/nw18_nclt_unsecure_creditors/login")
);
const NW18_NCLT_UnSecured_dashboard = lazy(() =>
  import("./components/NCLT/NW18/nw18_nclt_unsecure_creditors/dashboard")
);
const NW18_NCLT_UnSecured_meeting_not_started = lazy(() =>
  import(
    "./components/NCLT/NW18/nw18_nclt_unsecure_creditors/meetingnotstarted"
  )
);
const NW18_NCLT_UnSecured_authorize = lazy(() =>
  import("./components/NCLT/NW18/nw18_nclt_unsecure_creditors/authorize")
);

// Equity Shareholder
const NW18_NCLT_EQ_Home = lazy(() =>
  import("./components/NCLT/NW18/nw18EQncltmeeting/home")
);
const NW18_NCLT_EQ_Login = lazy(() =>
  import("./components/NCLT/NW18/nw18EQncltmeeting/login")
);
const NW18_NCLT_EQ_Dash = lazy(() =>
  import("./components/NCLT/NW18/nw18EQncltmeeting/dash")
);
const NW18_NCLT_EQ_meeting_not_started = lazy(() =>
  import("./components/NCLT/NW18/nw18EQncltmeeting/meetingnotstarted")
);
const NW18_NCLT_EQ_authorize = lazy(() =>
  import("./components/NCLT/NW18/nw18EQncltmeeting/authorize")
);
//----------------------NETWORK18 NCLT END----------------------------------------

// const RIL_AGM_2021_HOME = lazy(() =>
//   import("./components/AGMS/rilagm2021/home")
// );
// const RIL_AGM_2021_LANDING = lazy(() =>
//   import("./components/AGMS/rilagm2021/landing")
// );

// const RIL_AGM_2021_terms = lazy(() =>
//   import("./components/AGMS/rilagm2021/home/tnc")
// );
// const RIL_AGM_2021_policy = lazy(() =>
//   import("./components/AGMS/rilagm2021/home/privacyPolicy")
// );
// const RIL_AGM_2021_thankyou = lazy(() =>
//   import("./components/AGMS/rilagm2021/thankyou")
// );

// Alok agm 2023

const ALOK_AGM_2024_HOME = lazy(() =>
  import("./components/AGMS/alok_industries_agm/home")
);
const ALOK_AGM_2024_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/alok_industries_agm/login")
);
const ALOK_AGM_2024_dash = lazy(() =>
  import("./components/AGMS/alok_industries_agm/dash")
);

const ALOK_AGM_2024_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/alok_industries_agm/authorize")
);

const ALOK_AGM_2024_OthersLogin = lazy(() =>
  import("./components/AGMS/alok_industries_agm/othersLogin")
);
const ALOK_AGM_2024_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/alok_industries_agm/meetingnotstarted")
);

const ALOK_AGM_2024_ThankYou = lazy(() =>
  import("./components/AGMS/alok_industries_agm/thankyou")
);

// Lotus Chocolate agm 2024

const LOTUS_CHOCOLATE_AGM_2024_HOME = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/home")
);
const LOTUS_CHOCOLATE_AGM_2024_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/login")
);
const LOTUS_CHOCOLATE_AGM_2024_dash = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/dash")
);

const LOTUS_CHOCOLATE_AGM_2024_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/authorize")
);

const LOTUS_CHOCOLATE_AGM_2024_OthersLogin = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/othersLogin")
);
const LOTUS_CHOCOLATE_AGM_2024_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/meetingnotstarted")
);

const LOTUS_CHOCOLATE_AGM_2024_ThankYou = lazy(() =>
  import("./components/AGMS/lotus_chocolate_agm/thankyou")
);
// EIH agm 2023

const EIH_AGM_2021_HOME = lazy(() => import("./components/AGMS/eihagm/home"));
const EIH_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/eihagm/login")
);
const EIH_AGM_2021_dash = lazy(() => import("./components/AGMS/eihagm/dash"));

const EIH_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/eihagm/authorize")
);

const EIH_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/eihagm/othersLogin")
);
const EIH_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/eihagm/meetingnotstarted")
);

const EIH_AGM_2021_ThankYou = lazy(() =>
  import("./components/AGMS/eihagm/thankyou")
);

// hathway agm 2023

const HATHWAY_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/hathwayagm/home")
);
const HATHWAY_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/hathwayagm/login")
);
const HATHWAY_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/hathwayagm/dash")
);

const HATHWAY_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/hathwayagm/authorize")
);

const HATHWAY_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/hathwayagm/othersLogin")
);
const HATHWAY_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/hathwayagm/meetingnotstarted")
);

const HATHWAY_AGM_2021_ThankYou = lazy(() =>
  import("./components/AGMS/hathwayagm/thankyou")
);

// hbcdlagm 2023

const HBCDL_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/hbcdlagm/home")
);
const HBCDL_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/hbcdlagm/login")
);
const HBCDL_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/hbcdlagm/dash")
);

const HBCDL_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/hbcdlagm/authorize")
);

const HBCDL_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/hbcdlagm/othersLogin")
);
const HBCDL_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/hbcdlagm/meetingnotstarted")
);
//bkt agm 2023
const bktHome = lazy(() => import("./components/AGMS/bktagm/bkthome"));
const bktLogin = lazy(() => import("./components/AGMS/bktagm/bktlogin"));
const bktdash = lazy(() => import("./components/AGMS/bktagm/bktdash"));
const bktLanding = lazy(() => import("./components/AGMS/bktagm/bktLanding"));
const bktOthersLogin = lazy(() =>
  import("./components/AGMS/bktagm/othersLogin")
);
const BKTMeetingNotStarted = lazy(() =>
  import("./components/AGMS/bktagm/meetingnotstarted")
);

const BKTKFintechAuthorization = lazy(() =>
  import("./components/AGMS/bktagm/authorize")
);

// rretail agm 2023

const RRETAIL_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/rretail/home")
);

const RRETAIL_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/rretail/login")
);
const RRETAIL_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/rretail/dash")
);

const RRETAIL_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/rretail/authorize")
);

const RRETAIL_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/rretail/othersLogin")
);
const RRETAIL_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/rretail/meetingnotstarted")
);

// den agm 2023

const DEN_AGM_2021_HOME = lazy(() => import("./components/AGMS/denagm/home"));
const DEN_AGM_2021_Login = lazy(() => import("./components/AGMS/denagm/login"));
const DEN_AGM_2021_dash = lazy(() => import("./components/AGMS/denagm/dash"));
//const DEN_AGM_2021_Landing = lazy(() => import("./components/AGMS/denagm/Landing"));
const DEN_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/denagm/othersLogin")
);
const DEN_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/denagm/meetingnotstarted")
);

const DEN_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/denagm/authorize")
);

// TV18 brodcast  agm 2021

const TV18_AGM_2021_HOME = lazy(() => import("./components/AGMS/tv18agm/home"));

const TV18_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/tv18agm/login")
);
const TV18_AGM_2021_dash = lazy(() => import("./components/AGMS/tv18agm/dash"));

const TV18_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/tv18agm/authorize")
);

const TV18_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/tv18agm/othersLogin")
);
const TV18_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/tv18agm/meetingnotstarted")
);

// NW18 agm 2021

const NW18_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/network18agm/home")
);

const NW18_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/network18agm/login")
);

const NW18_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/network18agm/dash")
);

const NW18_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/network18agm/authorize")
);

const NW18_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/network18agm/othersLogin")
);
const NW18_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/network18agm/meetingnotstarted")
);

// infomedia agm 2021

const INFOMEDIA_AGM_2021_HOME = lazy(() =>
  import("./components/AGMS/infomediaagm/home")
);

const INFOMEDIA_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/infomediaagm/login")
);

const INFOMEDIA_AGM_2021_dash = lazy(() =>
  import("./components/AGMS/infomediaagm/dash")
);

const INFOMEDIA_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/infomediaagm/authorize")
);

const INFOMEDIA_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/infomediaagm/othersLogin")
);
const INFOMEDIA_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/infomediaagm/meetingnotstarted")
);

// riil agm 2024

const RIIL_AGM_2021_HOME = lazy(() => import("./components/AGMS/riilagm/home"));

const RIIL_AGM_2021_ShareHolder_Login = lazy(() =>
  import("./components/AGMS/riilagm/login")
);
const RIIL_AGM_2021_dash = lazy(() => import("./components/AGMS/riilagm/dash"));

const RIIL_AGM_2021_KFintechAuthorization = lazy(() =>
  import("./components/AGMS/riilagm/authorize")
);

const RIIL_AGM_2021_OthersLogin = lazy(() =>
  import("./components/AGMS/riilagm/othersLogin")
);
const RIIL_AGM_2021_MeetingNotStarted = lazy(() =>
  import("./components/AGMS/riilagm/meetingnotstarted")
);

// JFSagm

const JFS_Home = lazy(() => import("./components/AGMS/jfsagm/home"));

const JFS_Login = lazy(() => import("./components/AGMS/jfsagm/login"));

const JFS_Others_login = lazy(() =>
  import("./components/AGMS/jfsagm/othersLogin")
);

const JFS_dashboard = lazy(() => import("./components/AGMS/jfsagm/dashboard"));

const JFS_meeting_not_started = lazy(() =>
  import("./components/AGMS/jfsagm/meetingnotstarted")
);

const JFS_authorize = lazy(() => import("./components/AGMS/jfsagm/authorize"));

const JFS_Landing = lazy(() => import("./components/AGMS/jfsagm/landing"));

const JFS_Thank_You = lazy(() => import("./components/AGMS/jfsagm/thankyou"));

// ReactGA.initialize('UA-171289717-1', { debug: true });
// ReactGA.initialize('GTM-PPZ6XLQ', { debug: true });

//Tatvic Analytics Team ID: UA-171150917-1

const App = () => {
  const [auth, setAuth] = useState(false);

  return (
    <AuthApi.Provider value={{ auth, setAuth }}>
      <Router basename="/meetingportal/">
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
      </Router>
    </AuthApi.Provider>
  );
};

const Routes = () => {
  const Auth = React.useContext(AuthApi);

  return (
    <Switch>
      <ProtectedRoutes path="/dashboard" component={NEWDashboard} />
      <DisbaledRoutes path="/login" component={JudgeLogin} />
      <DisbaledRoutes path="/samllogin" component={SamlLogin} />
      <Route path="/samllogout" component={SamlLogout} />
      <Route exact path="/enter" component={EnterPage} />
      <Route exact path="/meeting-not-started" component={MeetingNotStarted} />
      <Route exact path="/agmhome" component={AGM_Home} />
      {/* <Route exact path="/agmhometest" component={AGM_Home_Dummy} />  */}
      <Route path="/agm/authorize" component={AGM_Authorize} />
      <AGMDisbaledRoutes
        exact
        shareholder="y"
        path="/agmlogin"
        basepath="/"
        component={AGM_Login}
      />
      <AGMDisbaledRoutes
        exact
        shareholder="n"
        path="/otherslogin"
        component={AGM_Other}
        basepath="/"
      />
      <AGMProtectedRoutes
        exact
        path="/agmdashboard"
        component={AGM_Dashboard}
        basepath="/"
      />

      {/* Hathway AGM */}
      {/* <Route exact path="/hathwayagm" component={HATHWAY_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/hathwayagm"
        path="/hathwayagm/shareholderlogin"
        component={HATHWAY_AGM_2021_ShareHolder_Login}
      />

      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/hathwayagm"
        path="/hathwayagm/othersLogin"
        component={HATHWAY_AGM_2021_OthersLogin}
      />

      <AGMProtectedRoutes
        exact
        basepath="/hathwayagm"
        path="/hathwayagm/dashboard"
        component={HATHWAY_AGM_2021_dash}
      />
      <Route
        exact
        path="/hathwayagm/meeting-not-started"
        component={HATHWAY_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/hathwayagm/authorize"
        component={HATHWAY_AGM_2021_KFintechAuthorization}
      />
      <Route
        exact
        path="/hathwayagm/thankyou"
        component={HATHWAY_AGM_2021_ThankYou}
      /> */}

      {/* HBCDL AGM */}
      {/* <Route exact path="/hbcdlagm" component={HBCDL_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/hbcdlagm"
        path="/hbcdlagm/shareholderlogin"
        component={HBCDL_AGM_2021_ShareHolder_Login}
      />

      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/hbcdlagm"
        path="/hbcdlagm/othersLogin"
        component={HBCDL_AGM_2021_OthersLogin}
      />

      <AGMProtectedRoutes
        exact
        basepath="/hbcdlagm"
        path="/hbcdlagm/dashboard"
        component={HBCDL_AGM_2021_dash}
      />
      <Route
        exact
        path="/hbcdlagm/meeting-not-started"
        component={HBCDL_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/hbcdlagm/authorize"
        component={HBCDL_AGM_2021_KFintechAuthorization}
      /> */}
      {/* bkt agm */}
      {/* <Route exact path="/bktagm" component={bktLanding} /> */}
      {/* <Route
        exact
        path="/bktagm/meeting-not-started"
        component={BKTMeetingNotStarted}
      />
      
      <Route path="/bktagm/authorize" component={BKTKFintechAuthorization} />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        path="/bktagm/otherslogin"
        component={bktOthersLogin}
      />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        path="/bktagm/shareholderlogin"
        component={bktLogin}
      />
      <AGMProtectedRoutes exact path="/bktagm/dashboard" component={bktdash} />
      <Route exact path="/bktagm" component={bktLanding} />
      <Route exact path="/bktagm" component={bktHome} /> 
      <Route exact path="/bktagm/joinmeeting" component={bktHome} /> */}

      {/* Alok Industries AGM */}

      {/*<Route exact path="/alokagm" component={ALOK_AGM_2024_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/alokagm"
        path="/alokagm/shareholderlogin"
        component={ALOK_AGM_2024_ShareHolder_Login}
      />

       <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/alokagm"
        path="/alokagm/othersLogin"
        component={ALOK_AGM_2024_OthersLogin}
      /> 

      <Route
        exact
        basepath="/alokagm"
        path="/alokagm/dashboard"
        component={ALOK_AGM_2024_dash}
      />
      <Route
        exact
        path="/alokagm/meeting-not-started"
        component={ALOK_AGM_2024_MeetingNotStarted}
      />
      <Route
        path="/alokagm/authorize"
        component={ALOK_AGM_2024_KFintechAuthorization}
    /> */}
      {/* <Route
        exact
        path="/alokagm/thankyou"
        component={ALOK_AGM_2024_ThankYou}
      /> */}

      {/*  JFSmeeting */}
      {/* <Route exact path="/jfsagm/joinmeeting" component={JFS_Home} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/jfsagm"
        path="/jfsagm/shareholderlogin"
        component={JFS_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/jfsagm"
        path="/jfsagm/othersLogin"
        component={JFS_Others_login}
      />
      <AGMDisbaledRoutes
        exact
        basepath="/jfsagm"
        path="/jfsagm/dashboard"
        component={JFS_dashboard}
      />
      <Route
        exact
        path="/jfsagm/meeting-not-started"
        component={JFS_meeting_not_started}
      />
      <Route path="/jfsagm/authorize" component={JFS_authorize} />
      <Route exact path="/jfsagm" component={JFS_Landing} /> */}
      {/* <Route exact path="/jfsagm" component={JFS_Thank_You} /> */}
      {/* <Route
        path="/jfsagm/authorize"
        component={JFS_KFintechAuthorization}
      /> */}

      {/* <Route exact path="/bktagm" component={bktHome} /> */}
      {/* <Route exact path="/bktagm/joinmeeting" component={bktHome} /> */}

      {/* // -- rilEQncltmeeting -- */}
      {/* <Route exact path="/rilEQncltmeeting" component={NCLT_EQ_Home} />
       <AGMDisbaledRoutes
        shareholder="y"
        exact
        path="/rilEQncltmeeting/login"
        basepath="/rilEQncltmeeting"
        component={NCLT_EQ_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/rilEQncltmeeting"
        path="/rilEQncltmeeting/dashboard"
        component={NCLT_EQ_Dash}
      />
      <Route
        exact
        path="/rilEQncltmeeting/meeting-not-started"
        component={NCLT_EQ_meeting_not_started}
      />
      <Route path="/rilEQncltmeeting/authorize" component={NCLT_EQ_authorize} /> */}

      {/* // rilSCncltmeeting */}
      {/* <Route exact path="/rilSCncltmeeting" component={NCLT_Secured_Home} />
       <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/rilSCncltmeeting"
        path="/rilSCncltmeeting/login"
        component={NCLT_Secured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/rilSCncltmeeting"
        path="/rilSCncltmeeting/dashboard"
        component={NCLT_Secured_dashboard}
      />
      <Route
        exact
        path="/rilSCncltmeeting/meeting-not-started"
        component={NCLT_Secured_meeting_not_started}
      />
      <Route
        path="/rilSCncltmeeting/authorize"
        component={NCLT_Secured_authorize}
      /> */}
      {/* // rilUSCncltmeeting */}
      {/*<Route exact path="/rilUSCncltmeeting" component={NCLT_UnSecured_Home} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/rilUSCncltmeeting"
        path="/rilUSCncltmeeting/login"
        component={NCLT_UnSecured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/rilUSCncltmeeting"
        path="/rilUSCncltmeeting/dashboard"
        component={NCLT_UnSecured_dashboard}
      /> */}

      {/* TV18 Secured NCLT START */}
      {/* <Route
        exact
        path="/tv18SCncltmeeting"
        component={TV18_NCLT_Secured_Home}
      /> */}
      {/* <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/tv18SCncltmeeting"
        path="/tv18SCncltmeeting/login"
        component={TV18_NCLT_Secured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/tv18SCncltmeeting"
        path="/tv18SCncltmeeting/dashboard"
        component={TV18_NCLT_Secured_dashboard}
      />
      <Route
        exact
        path="/tv18SCncltmeeting/meeting-not-started"
        component={TV18_NCLT_Secured_meeting_not_started}
      />
      <Route
        path="/tv18SCncltmeeting/authorize"
        component={TV18_NCLT_Secured_authorize}
      /> */}
      {/* TV18 Secured NCLT END */}

      {/* TV18 UNSECURED NCLT START */}
      {/* <Route
        exact
        path="/tv18USCncltmeeting"
        component={TV18_NCLT_UnSecured_Home}
      />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/tv18USCncltmeeting"
        path="/tv18USCncltmeeting/login"
        component={TV18_NCLT_UnSecured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/tv18USCncltmeeting"
        path="/tv18USCncltmeeting/dashboard"
        component={TV18_NCLT_UnSecured_dashboard}
      />
      <Route
        exact
        path="/tv18USCncltmeeting/meeting-not-started"
        component={TV18_NCLT_UnSecured_meeting_not_started}
      />
      <Route
        path="/tv18USCncltmeeting/authorize"
        component={TV18_NCLT_UnSecured_authorize}
      /> */}
      {/* TV18 UNSECURED NCLT END */}

      {/* TV18 EQUITY SHAREHOLDERS NCLT START*/}
      {/* <Route exact path="/tv18EQncltmeeting" component={TV18_NCLT_EQ_Home} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        path="/tv18EQncltmeeting/login"
        basepath="/tv18EQncltmeeting"
        component={TV18_NCLT_EQ_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/tv18EQncltmeeting"
        path="/tv18EQncltmeeting/dashboard"
        component={TV18_NCLT_EQ_Dash}
      />
      <Route
        exact
        path="/tv18EQncltmeeting/meeting-not-started"
        component={TV18_NCLT_EQ_meeting_not_started}
      />
      <Route
        path="/tv18EQncltmeeting/authorize"
        component={TV18_NCLT_EQ_authorize}
      /> */}
      {/* TV18 EQUITY SHAREHOLDERS NCLT END*/}

      {/* NW18 Secured NCLT START */}
      {/* <Route
        exact
        path="/nw18SCncltmeeting"
        component={NW18_NCLT_Secured_Home}
      /> */}
      {/* <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/nw18SCncltmeeting"
        path="/nw18SCncltmeeting/login"
        component={NW18_NCLT_Secured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/nw18SCncltmeeting"
        path="/nw18SCncltmeeting/dashboard"
        component={NW18_NCLT_Secured_dashboard}
      />
      <Route
        exact
        path="/nw18SCncltmeeting/meeting-not-started"
        component={NW18_NCLT_Secured_meeting_not_started}
      />
      <Route
        path="/nw18SCncltmeeting/authorize"
        component={NW18_NCLT_Secured_authorize}
      /> */}
      {/* NW18 Secured NCLT END */}

      {/* NW18 UNSECURED NCLT START */}
      {/* <Route
        exact
        path="/nw18USCncltmeeting"
        component={NW18_NCLT_UnSecured_Home}
      />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/nw18USCncltmeeting"
        path="/nw18USCncltmeeting/login"
        component={NW18_NCLT_UnSecured_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/nw18USCncltmeeting"
        path="/nw18USCncltmeeting/dashboard"
        component={NW18_NCLT_UnSecured_dashboard}
      />
      <Route
        exact
        path="/nw18USCncltmeeting/meeting-not-started"
        component={NW18_NCLT_UnSecured_meeting_not_started}
      />
      <Route
        path="/nw18USCncltmeeting/authorize"
        component={NW18_NCLT_UnSecured_authorize}
      /> */}
      {/* NW18 UNSECURED NCLT END */}

      {/* NW18 EQUITY SHAREHOLDERS NCLT START*/}
      {/* <Route exact path="/nw18EQncltmeeting" component={NW18_NCLT_EQ_Home} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        path="/nw18EQncltmeeting/login"
        basepath="/nw18EQncltmeeting"
        component={NW18_NCLT_EQ_Login}
      />
      <AGMProtectedRoutes
        exact
        basepath="/nw18EQncltmeeting"
        path="/nw18EQncltmeeting/dashboard"
        component={NW18_NCLT_EQ_Dash}
      />
      <Route
        exact
        path="/nw18EQncltmeeting/meeting-not-started"
        component={NW18_NCLT_EQ_meeting_not_started}
      />
      <Route
        path="/nw18EQncltmeeting/authorize"
        component={NW18_NCLT_EQ_authorize}
      /> */}
      {/* NW18 EQUITY SHAREHOLDERS NCLT END*/}

      {/* Tv18  */}
      {/* <Route exact path="/tv18broadcastagm" component={TV18_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/tv18broadcastagm"
        path="/tv18broadcastagm/shareholderlogin"
        component={TV18_AGM_2021_ShareHolder_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/tv18broadcastagm"
        path="/tv18broadcastagm/othersLogin"
        component={TV18_AGM_2021_OthersLogin}
      />
      <AGMProtectedRoutes
        exact
        basepath="/tv18broadcastagm"
        path="/tv18broadcastagm/dashboard"
        component={TV18_AGM_2021_dash}
      />
      <Route
        exact
        path="/tv18broadcastagm/meeting-not-started"
        component={TV18_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/tv18broadcastagm/authorize"
        component={TV18_AGM_2021_KFintechAuthorization}
      /> */}

      {/* NW18 */}
      <Route exact path="/network18milagm" component={NW18_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/network18milagm"
        path="/network18milagm/shareholderlogin"
        component={NW18_AGM_2021_ShareHolder_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/network18milagm"
        path="/network18milagm/othersLogin"
        component={NW18_AGM_2021_OthersLogin}
      />
      <AGMProtectedRoutes
        exact
        basepath="/network18milagm"
        path="/network18milagm/dashboard"
        component={NW18_AGM_2021_dash}
      />
      <Route
        exact
        path="/network18milagm/meeting-not-started"
        component={NW18_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/network18milagm/authorize"
        component={NW18_AGM_2021_KFintechAuthorization}
      />

      {/* GTPL Hathway AGM */}
      {/* <Route exact path="/gtplagm" component={GTPL_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/gtplagm"
        path="/gtplagm/shareholderlogin"
        component={GTPL_AGM_2021_ShareHolder_Login}
      />

      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/gtplagm"
        path="/gtplagm/othersLogin"
        component={GTPL_AGM_2021_OthersLogin}
      />

      <AGMProtectedRoutes
        exact
        basepath="/gtplagm"
        path="/gtplagm/dashboard"
        component={GTPL_AGM_2021_dash}
      />
      <Route
        exact
        path="/gtplagm/meeting-not-started"
        component={GTPL_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/gtplagm/authorize"
        component={GTPL_AGM_2021_KFintechAuthorization}
      /> */}

      {/* infomedia */}
      {/* <Route exact path="/infomediapressagm" component={INFOMEDIA_AGM_2021_HOME} />
      <Route
        exact
        path="/rilUSCncltmeeting/meeting-not-started"
        component={NCLT_UnSecured_meeting_not_started}
      />
      <Route
        path="/rilUSCncltmeeting/authorize"
        component={NCLT_UnSecured_authorize}
      />

      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/infomediapressagm"
        path="/infomediapressagm/shareholderlogin"
        component={INFOMEDIA_AGM_2021_ShareHolder_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/infomediapressagm"
        path="/infomediapressagm/othersLogin"
        component={INFOMEDIA_AGM_2021_OthersLogin}
      />
      <AGMProtectedRoutes
        exact
        basepath="/infomediapressagm"
        path="/infomediapressagm/dashboard"
        component={INFOMEDIA_AGM_2021_dash}
      />
      <Route
        exact
        path="/infomediapressagm/meeting-not-started"
        component={INFOMEDIA_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/infomediapressagm/authorize"
        component={INFOMEDIA_AGM_2021_KFintechAuthorization}
      /> */}

      {/* rrlagm */}
      {/* <Route exact path="/rrlagm" component={RRETAIL_AGM_2021_HOME} /> */}
      {/* <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/rrlagm"
        path="/rrlagm/shareholderlogin"
        component={RRETAIL_AGM_2021_ShareHolder_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/rrlagm"
        path="/rretail/othersLogin"
        component={RRETAIL_AGM_2021_OthersLogin}
      />

      <AGMProtectedRoutes
        exact
        basepath="/rrlagm"
        path="/rrlagm/dashboard"
        component={RRETAIL_AGM_2021_dash}
      />
      <Route
        exact
        path="/rrlagm/meeting-not-started"
        component={RRETAIL_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/rrlagm/authorize"
        component={RRETAIL_AGM_2021_KFintechAuthorization}
      /> */}

      {/* {just dial} */}
      {/* <Route exact path="/justdialagm" component={JUSTDIAL_AGM_2021_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/justdialagm"
        path="/justdialagm/shareholderlogin"
        component={JUSTDIAL_AGM_2021_ShareHolder_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/justdialagm"
        path="/justdialagm/othersLogin"
        component={JUSTDIAL_AGM_2021_OthersLogin}
      />
      <AGMProtectedRoutes
        exact
        basepath="/justdialagm"
        path="/justdialagm/dashboard"
        component={JUSTDIAL_AGM_2021_dash}
      />
      <Route
        exact
        path="/justdialagm/meeting-not-started"
        component={JUSTDIAL_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/justdialagm/authorize"
        component={JUSTDIAL_AGM_2021_KFintechAuthorization}/> */}

      {/* DEN agm */}
      {/* <Route exact path="/dennetworksagm" component={DEN_AGM_2021_HOME} />
      <Route
        exact
        path="/dennetworksagm/meeting-not-started"
        component={DEN_AGM_2021_MeetingNotStarted}
      />
      <Route
        path="/dennetworksagm/authorize"
        component={DEN_AGM_2021_KFintechAuthorization}
      />

      <AGMDisbaledRoutes
        shareholder="n"
        exact
        path="/dennetworksagm/othersLogin"
        component={DEN_AGM_2021_OthersLogin}
      />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        path="/dennetworksagm/shareholderlogin"
        component={DEN_AGM_2021_Login}
      />
      <AGMProtectedRoutes
        exact
        path="/dennetworksagm/dashboard"
        component={DEN_AGM_2021_dash}
      /> */}

      {/* Lotus Chocolate AGM */}

      <Route exact path="/lotuschocolateagm" component={LOTUS_CHOCOLATE_AGM_2024_HOME} />
      <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/lotuschocolateagm"
        path="/lotuschocolateagm/shareholderlogin"
        component={LOTUS_CHOCOLATE_AGM_2024_ShareHolder_Login}
      />

      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/lotuschocolateagm"
        path="/lotuschocolateagm/othersLogin"
        component={LOTUS_CHOCOLATE_AGM_2024_OthersLogin}
      />

      <Route
        exact
        basepath="/lotuschocolateagm"
        path="/lotuschocolateagm/dashboard"
        component={LOTUS_CHOCOLATE_AGM_2024_dash}
      />
      <Route
        exact
        path="/lotuschocolateagm/meeting-not-started"
        component={LOTUS_CHOCOLATE_AGM_2024_MeetingNotStarted}
      />
      <Route
        path="/lotuschocolateagm/authorize"
        component={LOTUS_CHOCOLATE_AGM_2024_KFintechAuthorization}
      />
      {/* <Route
        exact
        path="/lotuschocolateagm/thankyou"
        component={LOTUS_CHOCOLATE_AGM_2024_ThankYou}
      /> */}

      {/* ril agm */}
      {/* <AGMDisbaledRoutes
        shareholder="y"
        exact
        basepath="/rilagm"
        path="/rilagm/shareholderlogin"
        component={NCLT_Secured_Login}
      />
      <AGMDisbaledRoutes
        shareholder="n"
        exact
        basepath="/rilagm"
        path="/rilagm/othersLogin"
        component={NCLT_Secured_Login_others}
      />
      <AGMProtectedRoutes
        exact
        basepath="/rilagm"
        path="/rilagm/dashboard"
        component={NCLT_Secured_dashboard}
      />
      <Route
        exact
        path="/rilagm/meeting-not-started"
        component={NCLT_Secured_meeting_not_started}
      />
      <Route path="/rilagm/authorize" component={NCLT_Secured_authorize} />
      <Route exact path="/rilagm/joinmeeting" component={RIL_AGM_2021_HOME} />
      <Route exact path="/rilagm" component={RIL_AGM_2021_LANDING} />
      <Route path="/rilagm/terms" component={RIL_AGM_2021_terms} />
      <Route path="/rilagm/policy" component={RIL_AGM_2021_policy} /> */}
      {/* <Route exact path="/rilagm" component={RIL_AGM_2021_thankyou} /> */}
      {/* <Route path="/rilagm/terms" component={RIL_AGM_2021_terms} />
      <Route path="/rilagm/policy" component={RIL_AGM_2021_policy} />  */}
      {/* <Route exact path="/rilagm/thankyou" component={RIL_AGM_2021_thankyou} /> */}
      {/* <Route exact path="/rilagm" component={RIL_AGM_2021_HOME} /> */}
      {/* <Route exact path="/rilagm" component={RIL_AGM_2021_LANDING} /> */}
      {/* ril agm */}
      {/* <Route exact path="/rilagm" component={RIL_AGM_2021_HOME} /> */}

      <Route exact path="/preview" component={Preview} />
      {/* <DynamicRoute /> 
      <Route exact path="/:routename" component={AGM_LOADING} />*/}
      <Route exact path="/*" component={EventLanding} />
    </Switch>
  );
};

const DynamicRoute = () => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    async function fetchMyAPI() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      const response = await fetch(
        "https://testroutes.free.beeceptor.com/my/api/path",
        requestOptions
      );

      setRoutes(response.routes);
    }
    fetchMyAPI();
  }, []);

  if (routes === undefined)
    return <Route exact path="/*" component={EventLanding} />;

  return routes.map((item) => (
    <Route exact path={item.route_name} component={AGM_Home} />
  ));
};

const AGMProtectedRoutes = ({ Component, basepath, ...rest }) => {
  const history = useHistory();

  const previewAllow = window.sessionStorage.getItem("preview_allow");

  if (previewAllow === "1") {
    return <Route {...rest} render={() => <Component />} />;
  }

  if (!window.sessionStorage.getItem("shareholdar")) {
    history.replace(basepath);
    // return;
  }

  const auth = window.sessionStorage.getItem("loginKey");

  return (
    <Route
      {...rest}
      render={() => (auth ? <Component /> : <Redirect to={basepath} />)}
    />
  );
};

const AGMDisbaledRoutes = ({ shareholder, Component, basepath, ...rest }) => {
  const history = useHistory();

  // debugger;
  // // if (getTimeRemaining(LaunchDateTime).total > 0) {
  // //   history.replace('/joinmeeting');
  // // }

  const shareholdar = window.sessionStorage.getItem("screen_sha");
  const nonShareholdar = window.sessionStorage.getItem("screen_nsh");

  if (
    (shareholder === "y" &&
      (shareholdar === "" ||
        shareholdar === null ||
        shareholdar === undefined ||
        shareholdar === "N")) ||
    (shareholder === "n" &&
      (nonShareholdar === "" ||
        nonShareholdar === null ||
        nonShareholdar === undefined ||
        nonShareholdar === "N"))
  ) {
    history.replace(basepath);
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (
          (shareholder === "y" &&
            (shareholdar === "" ||
              shareholdar === null ||
              shareholdar === undefined ||
              shareholdar === "N")) ||
          (shareholder === "n" &&
            (nonShareholdar === "" ||
              nonShareholdar === null ||
              nonShareholdar === undefined ||
              nonShareholdar === "N"))
        ) {
          return <Redirect to={basepath} />;
        }
        return <Component />;
      }}
    />
  );
};

const ProtectedRoutes = ({ Component, ...rest }) => {
  const history = useHistory();

  const authStatus = window.sessionStorage.getItem("loginKey");

  const previewAllow = window.sessionStorage.getItem("preview_allow");

  if (previewAllow === "1") {
    return <Route {...rest} render={() => <Component />} />;
  }

  if (!authStatus) {
    history.replace("/login");
    // return;
  }

  return (
    <Route
      {...rest}
      render={() => (authStatus ? <Component /> : <Redirect to="/login" />)}
    />
  );
};

const DisbaledRoutes = ({ Component, ...rest }) => {
  const history = useHistory();

  // debugger;
  // // if (getTimeRemaining(LaunchDateTime).total > 0) {
  // //   history.replace('/joinmeeting');
  // // }

  const shareholdar = window.sessionStorage.getItem("event_id");

  if (
    shareholdar === "" ||
    shareholdar === null ||
    shareholdar === undefined ||
    shareholdar === "N"
  ) {
    history.replace("/");
  }

  return (
    <Route
      {...rest}
      render={() => {
        if (
          shareholdar === "" ||
          shareholdar === null ||
          shareholdar === undefined ||
          shareholdar === "N"
        ) {
          return <Redirect to="/" />;
        }
        return <Component />;
      }}
    />
  );
};

export default memo(App);
